html {

    // for scrollTo
    height: auto !important;
    body {height: auto !important;}

    .lds-onboard2-page-icon {

        background: url(../../images/demos/onboard2/onboard-icons.png);
        background-size: 1000px 300px;
        width: 100px;
        height: 100px;
        margin-bottom: 30px;

        // names
        &.is-name-hours {background-position-x: 0;}
        &.is-name-business {background-position-x: -100px;}
        // &.is-name-account {background-position-x: -100px;}
        &.is-name-agreements {background-position-x: -200px;}
        &.is-name-location {background-position-x: -300px;}
        &.is-name-equiptment {background-position-x: -400px;}
        &.is-name-employees {background-position-x: -500px;}
        &.is-name-review {background-position-x: -600px;}
        &.is-name-addresses {background-position-x: -700px;}
    }

    .lds-onboard2-page-title {
        color: #101828;
        text-align: center;
        font-weight: 400;
        font-size: 32px;
        line-height: 34px;
        margin-bottom: 30px;
    }

    .lds-onboard2-page-description {
        max-width: 350px;
        padding-bottom: 32px;
        color: var(--Gray-700, #374D58);
        text-align: center;
        /* Text lg/Regular */
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 155.556% */
    }

    .lds-onboard2-page-line {
        border-top: #CECECE 1px solid;
        width: 630px;
        margin-bottom: 50px;
    }


    // onboard nav
    .lds-onboard2-topnav {
        display: flex;


        // modifiers
        // &.is-selected 

        // members
        .lds-icon {
            z-index: 1;
            margin-bottom: 14px;
        }

        &-item {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100px;
            align-items: center;

            color: #374D58;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            
            // modifiers
            &.is-clickable {
                cursor: pointer;
                // color: #2A9B02;
            }
            &.is-status-current {
                cursor: pointer;
                color: #0093E8;
            }

            &.is-status-complete .lds-onboard2-topnav-line div,
            &.is-status-current .lds-onboard2-topnav-leftline {
                border-color: #0093E8;   
            }
        }

        &-line {
            display: block;
            position: absolute;
            top: 12px;
            left: 0;
            width: 100%;
            height: 2px;
        }

        &-leftline,
        &-rightline {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            border-top: #C4CFD4 1px solid;
        }

        &-rightline {
            left: 50%;
        }
    }

    // screamn


    // #lds-onboard1-email-singleowner {
    //     background: url(../../images/demos/onboard1/onboard1-email-singleowner.png);
    //     background-size: 100%;
    //     width: 955px;
    //     height: 663px;
    // }
    .lds-onboard2-password {
        background: url(../../images/demos/onboard2/ob-password.png);
        background-size: 100%;
        width: 469px;
        height: 481px;
    }

    .lds-onboard2-login {
        background: url(../../images/demos/onboard2/ob-login.png);
        background-size: 100%;
        width: 350px;
        height: 350px;
    }

    .lds-onboard2-welcome {
        background: url(../../images/demos/onboard2/ob-welcome.png);
        background-size: 100%;
        width: 400px;
        height: 1233px;
    }

    .lds-onboard2-employees {
        background: url(../../images/demos/onboard2/ob-employees.png);
        background-size: 100%;
        width: 770px;
        height: 594px;
    }

    .lds-onboard1.is-step-employees .lds-onboard2-page-description {
        max-width: 350px;
    }

    .lds-onboard2-agreements {
        background: url(../../images/demos/onboard2/ob-agreements.png);
        background-size: 100%;
        width: 444px;
        height: 191px;
    }

    .lds-onboard2-addresses {
        background: no-repeat url(../../images/demos/onboard2/ob-addresses.png);
        background-size: 100%;
        width: 500px;
        height: 879px;
    }

    .lds-onboard2-equiptment  {
        background: no-repeat url(../../images/demos/onboard2/ob-equiptment.png);
        background-size: 100%;
        width: 350px;
        height: 103px;
    }

    .lds-onboard1.is-step-equiptment .lds-onboard2-page-description {
        max-width: 350px;
    }

    .lds-onboard1.is-step-equiptment .lds-onboard2-page-description {
        max-width: 350px;
    }

    .lds-onboard2-account {
        background: no-repeat url(../../images/demos/onboard2/ob-account.png);
        background-size: 100%;
        width: 350px;
        height: 1701px;
    }

    .lds-onboard2-business { // --- NEW
        background: no-repeat url(../../images/demos/onboard2/ob-business.png);
        background-size: 100%;
        width: 500px;
        height: 347px;
    }

    .lds-onboard2-location { // --- NEW
        background: no-repeat url(../../images/demos/onboard2/ob-location.png);
        background-size: 100%;
        width: 500px;
        height: 1579px;
    }

    .lds-onboard2-hours {
        background: url(../../images/demos/onboard2/ob-hours.png);
        background-size: 100%;
        width: 568px;
        height: 1143px;
    }

    .lds-onboard2-services {
        background: url(../../images/demos/onboard2/ob-services.png);
        background-size: 100%;
        width: 800px;
        height: 500px;
    }

    .lds-onboard2-review {
        background: no-repeat url(../../images/demos/onboard2/ob-review.png);
        background-size: 100%;
        width: 688px;
        height: 1752px;
    }

    .lds-onboard2-success {
        background: no-repeat url(../../images/demos/onboard2/ob-success.png);
        background-size: 100%;
        width: 485px;
        height: 994px;
    }

} // html