


.lds-layout {
    display: inline-block;
    text-align: center;
    height: 44px;
    padding: 12px 16px;
    min-width: 120px;
    height: 44px;
    color: #374D58;
    background: white;
    border: 1px solid #BCC8CE;
    border-radius: 6px;
    cursor: pointer;

    // fonts
    font-family: 'Lato';


    // modifiers
    &.is-type-primary {
        color: white;
        background: #0093E8;
        border-width: 0;
    }

    &.is-width-auto {
        min-width: none;
        width: auto;
    }

    &-top {
        height: 72px;
    }
}


// --- 3col --- //

.lds-layout-3col {
    width: 100%;
    height: 100%;

    > table {
        width: 100%;
        height: 100%;;
    }


    .lds-layout-left {
        width: 270px;
        background-color: #F5F9FD;
    }

    .lds-layout-main {
        width: auto;
        padding-left: 24px;
        padding-right: 24px;
    }

    .lds-layout-right {
        width: 368px;
        padding-left: 24px;
        border-left: #E1EAEF 1px solid;
    }
}

// --- 3col --- //

.lds-layout-withpanel {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    min-height: 100%; // --- NEW

    // modifiers
    &.is-showpanel .lds-layout-panel {
        display: block;
        max-width: 368px;
    }

    // members
    .lds-layout-content {
        position: relative;
        top: 0;
        left: 0;
        padding: 0 24px;
        width: 100%;
        // overflow-x: hidden;

        &.is-nopadding {
            padding: 0;
        }
    }



    // panel
    .lds-layout-panel {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        transition: all .6s ease-out;
        transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
        width: 100%;
        max-width: 0;
        border-left: #E1EAEF 1px solid;
        z-index: 2;


        // modifiers
        &.is-nopadding {padding: 0;}

        &-closer {
            position: absolute;
            top: 10px;
            left: 300px;
            width: 50px;
            height: 50px;
            z-index: 1;
            cursor: pointer;
        }
    }

    .lds-layout-sidenav.is-settings {
        max-width: 250px !important;
        min-width: 250px !important;

        &-outer {
            max-width: 270px;
            min-width: 270px;
        }
    }

    // sidenav
    .lds-layout-sidenav {
        position: fixed;
        top: 72px;
        left: 0;
        display: block;
        width: 100%;
        max-width: 270px;
        min-width: 270px;
        background-color: #F5F9FD;

        // modifiers
        &.is-settings {
            max-width: 250px;
            min-width: 250px;

            .lds-layout-sidenav-selection {
                font-size: 16px;
                padding-left: 52px;
                font-weight: 400;
            }
        }

        &-outer {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            max-width: 270px;
            min-width: 270px;
            background-color: #F5F9FD;

            // modifiers
            &.is-settings {
                max-width: 250px;
                min-width: 250px;
            }
        }

        &-cta {
            top: 24px;
            left: 20px;
            width: 230px;
        }


        &-selection {
            top: 0;
            left: 0;
            width: 100%;
            height: 36px;
            // background-color: #CEECFF;
            background-color: rgba(0, 155, 255, 0.16);;
            padding: 8px 24px 0 54px;
            text-align: left;
            z-index: 2;
            cursor: default;
            // z-index: 2;

            // font
            color: #0093E8;
            font-size: 14px;
            font-family: Lato;
            font-weight: 500;
            letter-spacing: .3px;
            line-height: 20px;

            .lds-icon {
                display: none; // TEMP
                position: absolute;
                top: 8px;
                left: 24px;
            }

            span {
                background-color: #CEEAFD;
                padding-right: 4px;
            }
        }

        &-item {
            top: 0;
            left: 0;
            width: 100%;
            height: 36px;
        }

        &-hotspot {
            top: 0;
            left: 0;
            width: 100%;
            height: 36px;
            z-index: 1;
        }
    }
}
