@import "./fonts.scss";
@import "./colors.scss";
@import "./icons.scss";
@import "./graphics.scss";
@import "./sprites.scss";
@import "./sprites-app.scss";


// TEST:
@font-face {
    font-family: 'Lato-Medium';
    src:  url(./fonts/Lato-Medium.woff2) format('woff2'), url(./fonts/Lato-Medium.woff) format('woff');
}
@font-face {
    font-family: 'Lato-Semibold';
    src:  url(./fonts/Lato-Semibold.woff2) format('woff2'), url(./fonts/Lato-Semibold.woff) format('woff');
}
.is-text-medium {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato-Medium';
    font-style: normal;
    font-weight: 500;
}
.is-text-semi {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato-Semibold';
    font-style: normal;
    font-weight: 600;
}
// /TEST

* {box-sizing: border-box !important;}

html { // HTML
    
    height: 100%;
    min-width: 1320px; // GLOBAL MIN WIDTH
    // overflow-x: auto; // OLD
    overflow-x: hidden;
    overflow-y: auto;


    // reset table
    table, table tr, tr td, table tbody, table thead, table tfoot, table tr th, table tfoot tr tf {
        margin:0; padding:0; background:none; border:none; border-collapse:collapse; border-spacing:0; background-image:none;
    }

    // general

    body {
        padding: 0;
        margin: 0;
        font-family: 'Lato';
        height: 100%;
        padding-top: 72px;
        // width: 100%;
        // min-width: 1100px;
        // overflow-x: auto;

        #root, 
        #lds-page {height: 100%;}
    }

    td {
        vertical-align: top;
    }


    ul, ul li {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    ol {
        padding-left: 20px; 
    }



    // demos - special case
    body.lds-body-demos {
        padding-top: 0;
        .lds-page-demos > .lds-topnav {display: none;}
    }


    // global modifiers
    .is-debug,
    .is-debug-child > * {box-shadow: inset 0 0 3px red !important;}

    // fullscreen
    .is-centerstage {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    // absolute
    .is-abs {position: absolute; top: 0; left: 0; }
    .is-abs-right {position: absolute; left: auto; right: 0; }
    .is-abs-all {}
    .is-abs-all > * {position: absolute; }

    // cursor
    .is-cursor-link {cursor: pointer;}
    .is-cursor-text {cursor: text;}

    // align
    .is-align-right {text-align: right !important;}
    .is-align-center {text-align: center !important;}
    .is-align-left {text-align: left !important;}

    .is-nowrap {white-space: nowrap;}

    .is-card {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        overflow: hidden;
    }

    // --- overrides --- //

    .lds-body-demos .MuiDialog-paper { // --- TEMP
        max-width: 600px !important;
        h2 {
            font-weight: bold;
            padding-bottom: 0;
        }
        li {
            padding: 4px 0;
        }
    }

    #lds-body-demos-formerror1.lds-body-demos .MuiDialog-paper  {
        max-width: 800px !important;
        max-height: 800px !important;
        
    }
    

    // --- pages --- //

    body.lds-is-mobile {
        padding-top: 0;
        background-color: #555555;
    }

    .lds-page-home {
        background-color: #F5F9FD;
    }

    .lds-page {
        overflow-x: hidden;
    }

    .lds-page,
    .lds-maincontent {
        height: 100%;
        position: relative;
    }

    // sidenav bg
    .lds-body-forms,
    .lds-body-videos,
    .lds-body-videos-details,
    .lds-body-videos-details,
    // .lds-page-schedule,
    // .lds-page-messages,
    .lds-page-tasks222 {
        background: url(../images/sidenavs/sidebar-bg.png) repeat-y; 
        background-size: 270px 20px; 
        // width: 270px; 
        // height: 20px;

        #lds-main-loader {
            width: 1040px;
            left: 270px;
        }
    }
    

    // --- components --- //


} // HTML